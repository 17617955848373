@use '../node_modules/normalize.css/normalize.css';
@use '../node_modules/mapbox-gl/dist/mapbox-gl.css';
@use '../node_modules/choices.js/src/styles/choices';

.barlow-condensed-thin {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 100;
    font-style: normal;
  }

  .barlow-condensed-extralight {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 200;
    font-style: normal;
  }

  .barlow-condensed-light {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 300;
    font-style: normal;
  }

  .barlow-condensed-regular {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .barlow-condensed-medium {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  .barlow-condensed-semibold {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
    font-style: normal;
  }

  .barlow-condensed-bold {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  .barlow-condensed-extrabold {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 800;
    font-style: normal;
  }

  .barlow-condensed-black {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 900;
    font-style: normal;
  }

  .barlow-condensed-thin-italic {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 100;
    font-style: italic;
  }

  .barlow-condensed-extralight-italic {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 200;
    font-style: italic;
  }

  .barlow-condensed-light-italic {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 300;
    font-style: italic;
  }

  .barlow-condensed-regular-italic {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 400;
    font-style: italic;
  }

  .barlow-condensed-medium-italic {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 500;
    font-style: italic;
  }

  .barlow-condensed-semibold-italic {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
    font-style: italic;
  }

  .barlow-condensed-bold-italic {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 700;
    font-style: italic;
  }

  .barlow-condensed-extrabold-italic {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 800;
    font-style: italic;
  }

html,
body {
    height: 100vh;
}

body {
    font-family: "Source Sans 3", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    display: flex;
    flex-direction: column;
}

.brand-bar {
    color: #fff;
    background-color: #114f52;
    padding: 4px 20px 5px;
    display: flex;
    justify-content: space-between;
    container-type: inline-size;
}

.brand-bar .full {
  display: none;
}

@container (min-width: 500px ) {
  .brand-bar .full {
    display: inline-block;
  }
  .brand-bar .short {
    display: none;
    }
}

.brand-bar a {
    color: inherit;
    text-decoration: none;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .2px;
    opacity: 0.8;
    transition: opacity 150ms ease;
}

.brand-bar a:hover {
    opacity: 1;
}

.brand-bar .menu {
    display: flex;
    gap: 20px;
    padding: 0;
    margin: 0;
}

.brand-bar .menu-item {
    list-style-type: none;
}

.section--juggling-club-map {
    flex: 1;
  display: flex;
  flex-direction: column;
}

.container {
    max-width: 1200px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    width: calc(100% - 40px);
}

.section-header {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 10;
}

.section-header__inner {
    padding: 20px 0;

    @media (min-width: 767px) {
      display: grid;
      align-items: baseline;
      grid-template-columns: repeat(1, 1fr);
    }
}

.section-header__heading {
    margin: 0;
    @media (min-width: 767px) {
      grid-column: 1/2;
    }
}

.section-header__subhead {
    font-weight: 400;
    font-size: 18px;
    margin: 0 0 20px;
    @media (min-width: 767px) {
      grid-column: 1/2;
      margin-bottom: 0;
    }
}

.location-filter {
    grid-column: 2/3;
    grid-row: 2;
    justify-self: flex-end;
    align-items: center;
    display: flex;
    gap: 8px;
}

.location-filter .choices {
  width: 170px;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 0;
}

.choices__inner {
  min-height: initial;
  padding: 0;
}

.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
  min-width: 170px;
}

.map {
    width: 100%;
    flex: 1;
}

.mapboxgl-popup {
    font:
        14px/20px 'Helvetica Neue',
        Arial,
        Helvetica,
        sans-serif;
    color: #fff;
    min-width: 280px;
}

.mapboxgl-popup-content {
    border-radius: 20px;
    box-shadow: 0 0.4px 3.6px rgba(0, 0, 0, 0.004),
    0 1px 8.5px rgba(0, 0, 0, 0.01), 0 1.9px 15.7px rgba(0, 0, 0, 0.019),
    0 3.4px 28.2px rgba(0, 0, 0, 0.03), 0 6.3px 54.4px rgba(0, 0, 0, 0.047),
    0 15px 137px rgba(0, 0, 0, 0.07);
    padding: 17px 20px 20px;
    background-color: #23a197;
    background: linear-gradient(300deg, rgb(35, 161, 151) 0%, rgb(35, 154, 144) 100%);
}

.popup__header {
    margin-bottom: 14px;
}

.popup__body {
    display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
  align-items: center;
}

.popup__title {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    padding-right: 40px;
    color: #fff;
}

.popup__address {
    opacity: 0.7;
    color: #fff;
    grid-column: span 2;
}

.button {
    letter-spacing: .2px;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  background-color: #28B8AC;
  border-radius: 26px;
  padding: 6px 15px;
  display: inline-block;
}

.popup__last_updated {
    opacity: .7;
    font-size: 12px;
    color: #0F433F;
    line-height: 1.2;
}

.mapboxgl-popup-close-button {
    font-size: 24px;
    font-weight: 200;
    right: 8px;
    top: 8px;
    color: #999;
    border-radius: 18px;
    height: 30px;
    width: 30px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
    transition: all 150ms ease;
    color: #fff;
    background-color: #1D867D;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: #23a197;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-top-color: #23a197;
}

.violator {
  margin: 0 auto;
  padding: 20px;
  background-color: #23a197;
  max-width: 1000px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 60px);
  border-radius: 10px;
  box-shadow: 0 0.4px 3.6px rgba(0, 0, 0, 0.004),
    0 1px 8.5px rgba(0, 0, 0, 0.01), 0 1.9px 15.7px rgba(0, 0, 0, 0.019),
    0 3.4px 28.2px rgba(0, 0, 0, 0.03), 0 6.3px 54.4px rgba(0, 0, 0, 0.047),
    0 15px 137px rgba(0, 0, 0, 0.07);
    color: #fff;
    z-index: 10;

    @media (min-width: 767px) {
      bottom: 20px;
    }
}